<template>
	<div class="Safty">
		
		<li>
			<span class="Title">
				<i class="el-icon-key"></i>
				修改密码
			</span>
			<span class="Tips">
				密码需包含字母、数字或符号中的两项且长度超过6位
			</span>
			<span class="Action">
				<label @click="$Jump('/my/safty/pas_account')">去修改</label>
			</span>
		</li>
		
		<li>
			<span class="Title">
				<i class="el-icon-lock"></i>
				修改二级密码
			</span>
			<span class="Tips">
				用于打开高级设置的权限页面时所需的密码
			</span>
			<span class="Action">
				<label @click="$Jump('/my/safty/pas_safty')">去修改</label>
			</span>
		</li>
		
		<li>
			<span class="Title">
				<i class="el-icon-key"></i>
				修改支付密码
			</span>
			<span class="Tips">
				政企版用户支付时输入的交易密码
			</span>
			<span class="Action">
				<label @click="$Jump('/my/safty/pas_pay')">去修改</label>
			</span>
		</li>
		
		<li>
			<span class="Title">
				<i class="el-icon-lock"></i>
				二级密码验证
			</span>
			<span class="Tips">
				开启或关闭二级密码验证
			</span>
			<span class="Action">
				<label @click="UpdateUser('IsUsePas2',1)">开启</label>
				<label @click="UpdateUser('IsUsePas2',2)">关闭</label>
			</span>
		</li>
		
		<li>
			<span class="Title">
				<i class="el-icon-message"></i>
				短信通知
			</span>
			<span class="Tips">
				开启或关闭短信通知
			</span>
			<span class="Action">
				<label @click="UpdateUser('IsSmsNotify',1)">开启</label>
				<label @click="UpdateUser('IsSmsNotify',2)">关闭</label>
			</span>
		</li>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Radio} from 'element-ui'
	export default {
	  name: 'Safty',
	  props: {
	  },
	  data() {
	      return {
			  User:null
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-radio':Radio
	  },
	  created() {
	  	this.GetUser()
	  },
	  methods:{
		  GetUser(){
		  			  let data = {
		  			  	Service:'User',
		  			  	Class: 'User',
		  			  	Action: 'Get',
		  			  	Id:''//普通用户只能调取自己的信息
		  				
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  			  	
		  			  	this.User = res.Data
		  				this.Mobile = res.Data.Mobile
		  			  	
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  UpdateUser(_switch,_val){
		  			  
		  			  let data = {
		  			  	Service:'User',
		  			  	Class: 'User',
		  			  	Action: 'Update',
		  			  }
					  data[_switch] = _val
					  
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  						this.$message(res.Msg)
		  			  	if(res.ErrorId != 0){
		  			  		return
		  			  	}
		  			  	
		  			  	this.GetUser()
		  			  	
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
	  }
	}
</script>

<style scoped>
.Safty{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 30px;
}
.Safty li{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	padding: 30px;
}
.Safty li .Title{
	width: 250px;
	font-size: 1.2rem;
}
.Safty li .Title i{
	margin-right: 10px;
}
.Safty li .Tips{
	flex: 1;
	border-left: 1px solid rgba(0,0,0,0.1);
	padding: 10px 10px 10px 20px;
	color: rgba(0,0,0,0.3);
}
.Safty li .Action{
	width: 150px;
	text-align: right;
}
.Safty li .Action label{
	margin-left: 10px;
	cursor: pointer;
}
.Safty li .Action label:hover{
	color: rgba(228,0,0,1);
}
</style>
